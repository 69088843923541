<template>
  <div class="body">
    <div class="container">
      <div class="contents">
        <div class="sub_logo">
          <img src="../assets/img/logo_sub.png" alt="필리핀센터로고와 대련센터로고">
        </div>
        <div class="login_box">
          <div class="title">
            <img src="../assets/img/carrot_logo_main.png" alt="캐럿로고">
            <p>People who discover the best<br>
              from each other and give the best.<br>
              We are CARROT.
            </p>
          </div>
          <div class="gate">
            <label class="subtitle">ID</label>
            <input type="text" v-model="login.uid"  placeholder="Please enter your ID."  required>
            <label class="subtitle">Password</label>
            <input type="password" v-model="login.upw" @keyup.enter="login.doEnter"  placeholder="Please enter your Password." onfocus="this.placeholder=''" onblur="this.placeholder='Please enter your Password.'" required>
            <label class="checkbox" ><input type="checkbox"  v-model="login.check">Remembering IDs.</label>
            <button  @click="login.doSubmit">LOGIN</button>
          </div>
        </div>
      </div>

      <footer>Copyright ⓒCARROT Inc. All rights reserved.</footer>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive} from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import { useStore } from 'vuex'
import { useCookies } from "vue3-cookies";

export default {
  components: {
  },
  setup() {
    const router = useRouter();
    const toast  = useToast();
    const store = useStore();
    const cookies = useCookies();
    const login = reactive({
      uid : "",
      upw : "",
      check:'N',

      doSubmit : () => {
        login.rememberId();
        let params = {
          uid : login.uid,
          upw : login.upw
        };

        if( !params.uid ){
          toast.error("Please enter both your ID and password.");
          return;
        }

        if( !params.upw ){
          toast.error("Please enter both your ID and password.");
          return;
        }


        axios.post("/rest/mypims/login", params).then((res) => {
          if( res.data.err == 0 ){
              store.commit('setUserInfo', res.data);
              store.commit('setAuth');
              router.push({
                name : 'system_select'
              });
          } else {
            if(res.data.err_msg) toast.error("Please check your ID or password.");
          }
        });
      },
      doEnter : () => {
        login.doSubmit();
      },
      checkId:()=>{
        login.check=cookies.cookies.get("id_check");
        if(login.check=='true')
          login.uid = cookies.cookies.get("id");
      },
      rememberId:()=>{
        cookies.cookies.set("id_check", login.check);
        cookies.cookies.set("id", login.uid);
        if(login.check=='true'){
          cookies.cookies.set("id", login.uid);
        }
        login.checkId();
      }
    });
    onMounted(() => {
      // Mounted
      login.checkId();


    });

    return {login};
  }
}
</script>


<style lang="scss" scoped>
.body {
  background-image: url(../assets/img/bg_gate.jpg);
  font-weight: 400;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  color: #282828;
}

.container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  width: 583px;
}
.contents {
  margin: 60px 10px;
  .sub_logo {
    text-align: right;
    padding: 0 10px 23px 0;
  }

  .login_box {
    width: 563px;
    background-color: #fff;
    border-radius: 28px;
    padding: 0 66px;
    box-shadow: 0px 7px 28px rgba(102, 2, 15, 0.08);
    .title {
      text-align: center;
      img {
        padding: 84px 0 25px 0;
      }
      p {
        padding: 0 0 20px 0;
      }
    }
  }
}

.gate {
  label {
    display: block;
    padding: 20px 0 15px 0;
    &.subtitle {
      font-size: 18px;
      font-weight: 700;
    }
    &.checkbox {
      padding: 24px 0 0 0;
      font-size: 14px;
      display: inline-block;
    }
  }
  input {
    width: 100%;
    font-size: 18px;
    border-radius: 12px;
    border: 2px solid #eeeeee;
    font-weight: 400;
    padding: 21px 0 21px 21px;
    height: auto;
    &::placeholder {
      color:#e0dddd;
      font-family: 'Noto Sans KR', sans-serif;
      font-weight: 300;
    }
    &::-ms-input-placeholder {
      color: #e0dddd;
      font-family: 'Noto Sans KR', sans-serif;
      font-weight: 300;
    }
    &::-webkit-input-placeholder {
      color: #e0dddd;
      font-family: 'Noto Sans KR', sans-serif;
      font-weight: 300;
    }
    &[type="checkbox"] {
      width: 15px;
      height: 15px;
      margin: 0 10px 0 0;
    }
    &:focus {
      outline: none;
    }
  }
  button {
    width: 100%;
    margin: 33px 0 78px 0;
    font-size: 20px;
    padding: 18px 0;
    border-radius: 38px;
    border: none;
    background: linear-gradient(70deg, #ff994e, #ff4068);
    color: #fff;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 700;
    box-shadow: 0px 5px 24px rgba(255, 64, 105, 0.39);
  }
}

footer {
  padding: 0 0 15px 0;
  font-size: 14px;
}
</style>